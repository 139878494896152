html,
body { 
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: Century-Gothic;
  src: local("Century-Gothic"),
    url(/public/fonts/Century-Gothic.ttf) format("truetype");
  src: local("PTSerif-Bold"),
    url(/public/fonts/PTSerif-Bold.ttf) format("truetype");
  src: local("PTSerif-BoldItalic"),
    url(/public/fonts/PTSerif-BoldItalic.ttf) format("truetype");
  src: local("PTSerif-Italic"),
    url(/public/fonts/PTSerif-Italic.ttf) format("truetype");
  src: local("PTSerif-Regular"),
    url(/public/fonts/PTSerif-Regular.ttf) format("truetype");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
} */
